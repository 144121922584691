import { Component, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, RequiredValidator, Validators } from '@angular/forms';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DataService } from 'src/app/_services/data.services';
// import { AuthenticationService } from 'src/app/_services';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-layout',
  templateUrl: './Learnmore-layout.component.html'
})
export class LearnmoreLayoutComponent implements OnInit {
  private toggleButton: any;
  private sidebarVisible: boolean;
  mobile_menu_visible: any = 0;
  private _router: Subscription;
  step=0;
  currentuser
  form: FormGroup;
  form1: FormGroup;
  user;
  steps=1;
  planid;
  brand;
  tokenstripe:any;
  picture;
  paymentHandler: any = null;
  stripeAPIKey: any = 'pk_test_51Lh4rAGS3eDs0tnAAjWqG4ama54l3RWwk0AqfdZqDJQAiilmPRPNYZQcTKjtEutZ4G2Hi6IVXaVOaUuJXjclgLVe00p7Xfnnd4';
  
constructor(private router: Router,private _formBuilder: FormBuilder,private dataService: AuthenticationService, private element: ElementRef) {
  this.sidebarVisible = false;
}
  ngOnInit(){
    if(localStorage.getItem("isrealtor"))
    {
      this.currentuser= localStorage.getItem("cUser") 
    }
    this.picture=localStorage.getItem("profile");

    this.invokeStripe();
    this.form = this._formBuilder.group({
      name: ['',Validators.required],
      email: ['', Validators.email],
      password: ['',Validators.required],
      passwordconfirm: ['',Validators.required],
      role: '2',
      phone: null,
      address: null,
      company_name: null,
      company_license_no: null,
      agent_license_no: null,
      zip_code: null,
    });
    this.form1 = this._formBuilder.group({
      email: ['', Validators.required],
      password: ['',Validators.required]
    });
    const navbar: HTMLElement = this.element.nativeElement;

    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      this.sidebarClose();
      const $layer = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
      }
    });
  }
  sidebarOpen() {
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];
      const toggleButton = this.toggleButton;
      const body = document.getElementsByTagName('body')[0];
      setTimeout(function(){
          toggleButton.classList.add('toggled');
      }, 500);
      body.classList.add('nav-open');
      setTimeout(function() {
          $toggle.classList.add('toggled');
      }, 430);

      var $layer = document.createElement('div');
      $layer.setAttribute('class', 'close-layer');


      if (body.querySelectorAll('.wrapper-full-page')) {
          document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }else if (body.classList.contains('off-canvas-sidebar')) {
          document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }

      setTimeout(function() {
          $layer.classList.add('visible');
      }, 100);

      $layer.onclick = function() { //asign a function
        body.classList.remove('nav-open');
        this.mobile_menu_visible = 0;
        this.sidebarVisible = false;

        $layer.classList.remove('visible');
        setTimeout(function() {
            $layer.remove();
            $toggle.classList.remove('toggled');
        }, 400);
      }.bind(this);

      body.classList.add('nav-open');
      this.mobile_menu_visible = 1;
      this.sidebarVisible = true;
  };
  sidebarClose() {
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];
      const body = document.getElementsByTagName('body')[0];
      this.toggleButton.classList.remove('toggled');
      var $layer = document.createElement('div');
      $layer.setAttribute('class', 'close-layer');

      this.sidebarVisible = false;
      body.classList.remove('nav-open');
      // $('html').removeClass('nav-open');
      body.classList.remove('nav-open');
      if ($layer) {
          $layer.remove();
      }

      setTimeout(function() {
          $toggle.classList.remove('toggled');
      }, 400);

      this.mobile_menu_visible = 0;
  };
  sidebarToggle() {
      if (this.sidebarVisible === false) {
          this.sidebarOpen();
      } else {
          this.sidebarClose();
      }
  }
  logout()
  {
    localStorage.clear();
    window.location.reload();
  }
  makePayment(amount: any) {
    if(amount==240)
    {
      this.planid="price_1Lh5ieGS3eDs0tnA9NtJqSvx";
    }
    else if(amount==700){
    this.planid="price_1Lh5jpGS3eDs0tnAYZg8V4ZU";
    }
    const paymentHandler = (<any>window).StripeCheckout.configure({
      key: this.stripeAPIKey,
      locale: 'auto',
      token: function (stripeToken: any) {
        //alert('Stripe token generated!'+stripeToken);
        console.log(stripeToken.id)
        $('#tokenstripe').val(stripeToken.id);
        $('#brand').val(stripeToken.card.brand);
        $('#submitid').click();
      }, 
    });
    paymentHandler.open({
      name: 'Realtor',
      description: 'RRN',
      amount: amount * 100,
    });
  }
  
  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: this.stripeAPIKey,
          locale: 'auto',
          token: function (stripeToken: any) {
            console.log(stripeToken);
            alert('Payment has been successfull!');
          },
        });
      };
  
      window.document.body.appendChild(script);
    }
  }


  get f() {return this.form.controls;}
  next()
  {
    if(this.form.invalid) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: 'Please Fill All Required Fields With Correct Format',
        showConfirmButton: false,
        timer: 2500
      })  
      return
    }
    else{
      this.steps=2;
    }
  }
  onSubmit()
  {
    if(this.form.invalid) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: 'Please Fill All Required Fields With Correct Format',
        showConfirmButton: false,
        timer: 2500
      })  
      return
    }
    const data = {
      name: this.f.name.value,
      email: this.f.email.value,
      phone: this.f.phone.value,
      address: this.f.address.value,
      password: this.f.password.value,
      password_confirmation: this.f.passwordconfirm.value,
      role: this.f.role.value,
      company_name: this.f.company_name.value,
      company_license_no: this.f.company_license_no.value,
      agent_license_no: this.f.agent_license_no.value,
      zip_code: this.f.zip_code.value,
      token:$('#tokenstripe').val(),
      brand:$('#brand').val(),
      plan:this.planid
    }
    console.log(data)
    this.dataService.post("auth/sign-up",data).then(
      data=>{
          console.log(data);
          if (data.success == true)
          {
           localStorage.setItem("token",data.data.token) 
           localStorage.setItem("cUser",data.data.user)
           if(data.data.user.role_id==2)
           {
            localStorage.setItem("isrealtor","true") 
            this.router.navigate(['/realtorportal/dashboard'])
           } 
           else if(data.data.user.role_id==3)
           {
            localStorage.setItem("isseller","true") 
            this.router.navigate(['/sellerportal/dashboard'])
           }
           else{
            localStorage.clear();
            location.href="#/realtor/home"
           }
            }
            else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                text: data.message,
                showConfirmButton: false,
                timer: 2500
              })  
          }     
      },
      er=> {
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: 'something went wrong',
          showConfirmButton: false,
          timer: 2500
        })  
      }
     
     );
  }
  get f1() {return this.form1.controls;}
  onlogin()
  {
    if(this.form1.invalid) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: 'Please Fill All Required Fields With Correct Format',
        showConfirmButton: false,
        timer: 2500
      })  
      return
    }
    const data = {
      email: this.f1.email.value,
      password: this.f1.password.value,
    }
    this.dataService.post("auth/sign-in",data).then(
      data=>{
          console.log(data);
          if (data.success == true)
          {
           localStorage.setItem("token",data.data.token) 
           localStorage.setItem("cUser",data.data.user)
           if(data.data.user.role_id==2)
           {
            localStorage.setItem("isrealtor","true") 
            this.router.navigate(['/realtorportal/dashboard'])
           } 
           else if(data.data.user.role_id==3)
           {
            localStorage.setItem("isseller","true") 
            this.router.navigate(['/sellerportal/dashboard'])
           }
           else{
            localStorage.clear();
            location.href="#/realtor/home"
           }
            }
            else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                text: data.message,
                showConfirmButton: false,
                timer: 2500
              })  
          }    
      },
      er=> {
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: 'something went wrong',
          showConfirmButton: false,
          timer: 2500
        })  
      }
     
     );
  }
}