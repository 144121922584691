import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, RequiredValidator, Validators } from '@angular/forms';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DataService } from 'src/app/_services/data.services';
// import { AuthenticationService } from 'src/app/_services';
import Swal from 'sweetalert2';
import * as $ from "jquery";

@Component({
  selector: 'app-layout',
  templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent implements OnInit {
  @ViewChild('forgotpassword') forgotpassword;
  @ViewChild('dismismodal') dismismodal;

  private toggleButton: any;
  private sidebarVisible: boolean;
  mobile_menu_visible: any = 0;
  private _router: Subscription;
  step=0;
  fstep=1;

  form: FormGroup;
  form1: FormGroup;
  forgotForm:FormGroup;
currentuser;
user;
picture;
userid;
  constructor(private router: Router,private _formBuilder: FormBuilder,private dataService: AuthenticationService, private element: ElementRef) {
      this.sidebarVisible = false;
  }
  ngOnInit(){
    this.currentuser= localStorage.getItem("cUser") 
    this.picture=localStorage.getItem("profile");
    this.form = this._formBuilder.group({
      name: ['',Validators.required],
      email: ['', Validators.email],
      password: ['',Validators.required],
      passwordconfirm: ['',Validators.required],
      role: '3',
      phone: null,
      address: null,
      company_name: null,
      company_license_no: null,
      agent_license_no: null,
      zip_code: null,
    });
    this.form1 = this._formBuilder.group({
      email: ['', Validators.required],
      password: ['',Validators.required]
    });
    this.forgotForm = this._formBuilder.group({
      email: null,
      password: null,
      cpassword: null,
      code: null,


    });
    const navbar: HTMLElement = this.element.nativeElement;

    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      this.sidebarClose();
      const $layer = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
      }
    });
  }
  sidebarOpen() {
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];
      const toggleButton = this.toggleButton;
      const body = document.getElementsByTagName('body')[0];
      setTimeout(function(){
          toggleButton.classList.add('toggled');
      }, 500);
      body.classList.add('nav-open');
      setTimeout(function() {
          $toggle.classList.add('toggled');
      }, 430);

      var $layer = document.createElement('div');
      $layer.setAttribute('class', 'close-layer');


      if (body.querySelectorAll('.wrapper-full-page')) {
          document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }else if (body.classList.contains('off-canvas-sidebar')) {
          document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }

      setTimeout(function() {
          $layer.classList.add('visible');
      }, 100);

      $layer.onclick = function() { //asign a function
        body.classList.remove('nav-open');
        this.mobile_menu_visible = 0;
        this.sidebarVisible = false;

        $layer.classList.remove('visible');
        setTimeout(function() {
            $layer.remove();
            $toggle.classList.remove('toggled');
        }, 400);
      }.bind(this);

      body.classList.add('nav-open');
      this.mobile_menu_visible = 1;
      this.sidebarVisible = true;
  };
  sidebarClose() {
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];
      const body = document.getElementsByTagName('body')[0];
      this.toggleButton.classList.remove('toggled');
      var $layer = document.createElement('div');
      $layer.setAttribute('class', 'close-layer');

      this.sidebarVisible = false;
      body.classList.remove('nav-open');
      // $('html').removeClass('nav-open');
      body.classList.remove('nav-open');
      if ($layer) {
          $layer.remove();
      }

      setTimeout(function() {
          $toggle.classList.remove('toggled');
      }, 400);

      this.mobile_menu_visible = 0;
  };
  sidebarToggle() {
      if (this.sidebarVisible === false) {
          this.sidebarOpen();
      } else {
          this.sidebarClose();
      }
  }
  logout()
  {
    // this.authService.logout();
    localStorage.clear();
    location.href="";
  }
  login()
  {
   // localStorage.setItem('currentUser', JSON.stringify("abc"));
   // window.location.href="#/sellerportal/dashboard";
   // window.location.reload();
  }
  get f() {return this.form.controls;}
  get f1() {return this.form1.controls;}
  get f2() {return this.forgotForm.controls;}

  onSubmit()
  {
    if(this.form.invalid) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: 'Please Fill All Required Fields With Correct Format',
        showConfirmButton: false,
        timer: 2500
      })  
      return
    }

    const formData = this.form.getRawValue();

    const data = {
      name: this.f.name.value,
      email: this.f.email.value,
      phone: this.f.phone.value,
      address: this.f.address.value,
      password: this.f.password.value,
      password_confirmation: this.f.passwordconfirm.value,
      role: this.f.role.value,
      company_name: this.f.company_name.value,
      company_license_no: this.f.company_license_no.value,
      agent_license_no: this.f.agent_license_no.value,
      zip_code: this.f.zip_code.value,
    }
    this.dataService.post("auth/sign-up",data).then(
      data=>{
          console.log(data);
          if (data.success == true)
          {
            localStorage.clear();

           localStorage.setItem("token",data.data.token) 
           localStorage.setItem("cUser",data.data.user) 
           localStorage.setItem("profile",data.data.user.picture) 

           if(data.data.user.role_id==2)
           {
            localStorage.setItem("isrealtor","true") 
            this.router.navigate(['/realtorportal/dashboard'])
           } 
           else if(data.data.user.role_id==3)
           {
            localStorage.setItem("isseller","true") 
            this.router.navigate(['/sellerportal/dashboard'])
           }
           else{
            localStorage.clear();
            location.href=""
           }    
          }
            else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                text: data.message,
                showConfirmButton: false,
                timer: 2500
              })  
          }    
      },
      er=> {
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: 'something went wrong',
          showConfirmButton: false,
          timer: 2500
        })  
      }
     
     );
  }
  onlogin()
  {
    if(this.form1.invalid) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: 'Please Fill All Required Fields With Correct Format',
        showConfirmButton: false,
        timer: 2500
      })  
      return
    }
    const data = {
      email: this.f1.email.value,
      password: this.f1.password.value,
    }
    this.dataService.post("auth/sign-in",data).then(
      data=>{
          console.log(data);
          if (data.success == true)
          {
            localStorage.clear();

           localStorage.setItem("token",data.data.token) 
           localStorage.setItem("cUser",data.data.user) 
           localStorage.setItem("profile",data.data.user.picture) 

           console.log(JSON.stringify(localStorage.getItem("cUser")));
           console.log(localStorage.getItem("profile"));

           if(data.data.user.role_id==2)
           {
            localStorage.setItem("isrealtor","true") 
            this.router.navigate(['/realtorportal/dashboard'])
           } 
           else if(data.data.user.role_id==3)
           {
            localStorage.setItem("isseller","true") 
            this.router.navigate(['/sellerportal/dashboard'])
           }
           else{
            localStorage.clear();
            location.href=""
           }    
          }
            else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                text: data.message,
                showConfirmButton: false,
                timer: 2500
              })  
          }    
      },
      er=> {
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: 'something went wrong',
          showConfirmButton: false,
          timer: 2500
        })  
      }
     
     );
  }
  sendcode()
  {
    if(this.f2.email.value==null || this.f2.email.value=="" ) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: 'Please Enter Email',
        showConfirmButton: false,
        timer: 2500
      })  
      return
    }
    const data = {
      email: this.f2.email.value,
    }
    this.dataService.post("forget-password",data).then(
      data=>{
          console.log(data);
          if (data.success == true)
          {
            this.fstep=2;
          }
            else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                text: data.data,
                showConfirmButton: false,
                timer: 2500
              })  
          }    
      },
      er=> {
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: 'something went wrong',
          showConfirmButton: false,
          timer: 2500
        })  
      }
     
     );

  }
  verifycode(){
    if(this.f2.email.value==null || this.f2.email.value=="", this.f2.code.value==null || this.f2.code.value=="") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: 'Please Fill All Required Fields With Correct Format',
        showConfirmButton: false,
        timer: 2500
      })  
      return
    }
    const data = {
      email: this.f2.email.value,
      token: this.f2.code.value
    }
    this.dataService.post("verify-token",data).then(
      data=>{
          console.log(data);
          if (data.success == true)
          {
            this.userid=data.data
            this.fstep=3;
          }
            else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                text: data.message,
                showConfirmButton: false,
                timer: 2500
              })  
          }    
      },
      er=> {
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: 'something went wrong',
          showConfirmButton: false,
          timer: 2500
        })  
      }
     
     );

  }
  clickforgetpassword()
  {
    this.fstep=1;
    this.forgotpassword.nativeElement.click();
    this.dismismodal.nativeElement.click();
  }
  onresetpassword()
  {
    if(this.userid==null || this.userid=="" || this.f2.password.value==null || this.f2.password.value==""|| this.f2.cpassword.value==null || this.f2.cpassword.value=="") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: 'Please Fill All Required Fields With Correct Format',
        showConfirmButton: false,
        timer: 2500
      })  
      return
    }
    const data = {
      user_id: this.userid,
      password: this.f2.password.value,
      confirm_password:this.f2.cpassword.value
    }
    this.dataService.post("reset-password",data).then(
      data=>{
          console.log(data);
          if (data.success == true)
          {
            Swal.fire({
              position: 'center',
              icon: 'success',
              text: data.message,
              showConfirmButton: false,
              timer: 2500
            }) 
            location.reload();        
         
            }
            else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                text: data.data,
                showConfirmButton: false,
                timer: 2500
              })  
          }    
      },
      er=> {
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: 'something went wrong',
          showConfirmButton: false,
          timer: 2500
        })  
      }
     
     );

  }
  }

