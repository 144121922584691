import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpResponse ,HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import{Router} from '@angular/router';
import 'rxjs/add/operator/catch';
import { AppSettings } from '../appSetting'
import {Observable} from 'rxjs/Rx';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public token:string;

    constructor(private http: HttpClient) { }
    login(email: string, password: string) {
       var form={
            'email': email,
    'password': password,
    'device_type': 'android'
        }
        return this.http.post<any>(`${AppSettings.base_url}${AppSettings.base_url}`, form)
        .catch((error) => {
            console.log(error.error.message);
            return Observable.throw(error.error.message);
          });

    }
    post(endpoint, body): Promise<any>
	{
		// this.token = JSON.parse(localStorage.getItem("currentUser")).auth_key;
		return this.http.post(`${AppSettings.base_url}` + endpoint, body)
			.toPromise().then((response: any) =>
			{
				if (response.status === 401) 
				{
					alert();
					localStorage.clear();
					location.href="";
					//window.location.reload();
				}
				else 
				{
					return response;
				}
			},
				(reason: any) =>
				{
					if (reason.error.status === 401) 
					{
						  localStorage.clear();
						  location.href="";
						  return reason;
					}
					return reason;

				}).catch(this.handleError);
	}
  
    logout() {
        // remove user from local storage to log user out 
        localStorage.removeItem('currentUser');
    }
  
    public handleError(error: any): Promise<any>
	{
		return error;
	}
}
