// import { Component, OnInit } from '@angular/core';
// import PerfectScrollbar from 'perfect-scrollbar';

// declare const $: any;

// //Metadata
// export interface RouteInfo {
//     path: string;
//     title: string;
//     type: string;
//     icontype: string;
//     collapse?: string;
//     children?: ChildrenItems[];
// }

// export interface ChildrenItems {
//     path: string;
//     title: string;
//     ab: string;
//     type?: string;
// }

// //Menu Items
// export const ROUTES: RouteInfo[] = [{
//         path: '/home',
//         title: 'Home',
//         type: 'link',
//         icontype: 'home'
//     },
//     {
//         path: '',
//         title: 'Users',
//         type: 'sub',
//         icontype: 'people',
//         collapse: 'Users',
//         children: [
//             {path: 'admin', title: 'Admin', ab:'CM'},
//             {path: 'registerUsers', title: 'Register Users', ab:'SM'},
//         ]
//     }
//     ,
//     {
      
//         path: '/loanRequest',
//         title: 'Loan Requests',
//         type: 'link',
//         icontype: 'paid' 
        
//     },{
//         path: '/account',
//         title: 'Payment Method',
//         type: 'link',
//         icontype: 'payments'
//     }
//     ,{
//         path: '/account',
//         title: 'Employee Feedback',
//         type: 'link',
//         icontype: 'feedback'
//     }
//     ,{
//         path: '/account',
//         title: 'Retailer Feedback',
//         type: 'link',
//         icontype: 'comment'
//     }
//     ,{
//         path: '/account',
//         title: 'Setting',
//         type: 'link',
//         icontype: 'settings_applications'
//     }
// ];
// @Component({
//     selector: 'app-sidebar-cmp',
//     templateUrl: 'sidebar.component.html',
// })

// export class SidebarComponent implements OnInit {
//     public menuItems: any[];
//     ps: any;
//     isMobileMenu() {
//         if ($(window).width() > 991) {
//             return false;
//         }
//         return true;
//     };

//     ngOnInit() {
//         this.menuItems = ROUTES.filter(menuItem => menuItem);
//         if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
//             const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
//             this.ps = new PerfectScrollbar(elemSidebar);
//         }
//     }
//     updatePS(): void  {
//         if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
//             this.ps.update();
//         }
//     }
//     isMac(): boolean {
//         let bool = false;
//         if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
//             bool = true;
//         }
//         return bool;
//     }
// }


import { Component, OnInit, Injectable } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import {AuthenticationService} from '../../_services';
import { Router } from '@angular/router';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
    forAdmin: boolean;
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    forAdmin: boolean;
}

//Menu Items
export const ROUTESAdmin: RouteInfo[] = [
               {
                path: '/home',
                title: 'Home',
                type: 'link',
                icontype: './assets/img/Assets/ic_home.png',
                forAdmin:true
            },
            {
                path: '',
                title: 'Users',
                type: 'sub',
                icontype: './assets/img/Assets/ic_Users.png',
                collapse: 'Users',
                forAdmin:false,
                children: [
                    {path: 'admin', title: 'Admins', ab:' ',forAdmin:true,
                },
                    {path: 'registerUsers', title: 'Register Users', ab:' ',forAdmin:true,
                },
                ]
            }
            ,
            {
              
                path: '/loanRequest',
                title: 'Loan Requests',
                type: 'link',
                icontype: './assets/img/Assets/ic_loan_requests.png',
                forAdmin:true
                
            }
            ,{
                path: '/employerfeedback',
                title: 'Employee Feedback',
                type: 'link',
                icontype: './assets/img/Assets/ic_employersfeedback.png',
                forAdmin:true
            }
            ,{
                path: '/account',
                title: 'Retailer Feedback',
                type: 'link',
                icontype: './assets/img/Assets/ic_Retailers Feedback.png',
                forAdmin:true
            }
            ,{
                path: '/contactus',
                title: 'Contact Us',
                type: 'link',
                icontype: './assets/img/Assets/ic_employersfeedback.png',
                forAdmin:true
            }
            ,{
                path: '/account',
                title: 'Setting',
                type: 'link',
                icontype: './assets/img/Assets/ic_setting.png',
                forAdmin:true
                
            }
];

// export const ROUTESUser: RouteInfo[] = [
//     {
//         path: '/flowchart',
//         title: 'Flow Chart',
//         type: 'sub',
//         collapse: 'district_menu',
//         icontype: 'transform',
//         forAdmin: false,
//         children: [
//             {
//                 path: 'view',
//                 ab: 'visibility',
//                 title: 'View', forAdmin: false,
//             }
//         ]
//     },
//     {
//         path: '/procedure',
//         title: 'Procedures',
//         type: 'sub',
//         collapse: 'procedure_menu',
//         icontype: 'do_not_step',
//         forAdmin: false,
//         children: [
//             {
//                 path: 'view',
//                 ab: 'visibility',
//                 title: 'View', forAdmin: false,
//             }
//         ]
//     },

//     {
//         path: '/help',
//         title: 'Help',
//         type: 'link',
//         icontype: 'contact_support',
//         forAdmin: false,
//     }
// ];


@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    currentUser:any;
    ps: any;
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };
    constructor(private authService:AuthenticationService, private router : Router){

    }

    logout(){
        this.authService.logout();
        this.router.navigate(['/pages/login']);
    }
    ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // if (this.currentUser.role=='Admin') {
            this.menuItems = ROUTESAdmin.filter(menuItem => menuItem);
        // }
      
        // else  {
        //     this.menuItems = ROUTESUser.filter(menuItem => menuItem)
        // }
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
