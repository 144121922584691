import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './Admin/layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './Admin/layouts/auth/auth-layout.component';
import { LearnmoreLayoutComponent } from './Admin/layouts/Learnmore/Learnmore-layout.component';
import { RealtorsLayoutComponent } from './Admin/layouts/Realtors/Realtors-layout.component';
import { RealtorsportalLayoutComponent } from './Admin/layouts/Realtorsportal/Realtorsportal-layout.component';
import { SellerLayoutComponent } from './Admin/layouts/seller/seller-layout.component';
import { SellerportalLayoutComponent } from './Admin/layouts/Sellerportal/Sellerportal-layout.component';

export const AppRoutes: Routes = [
    {
      path: '',
      redirectTo: ' ',
      pathMatch: 'full',
    },  
    {
      path: '',
      component: AuthLayoutComponent,
      children: [
      {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      }]
    },
    {
      path: '',
      component: SellerLayoutComponent,
      children: [
      {
        path: 'seller',
        loadChildren: () => import('./Seller/Seller.module').then(m => m.SellerModule)
      }]
    }
    ,{
    path: '',
    component: SellerportalLayoutComponent,
    children: [
    {
      path: 'sellerportal',
      loadChildren: () => import('./Seller/Sellerportal.module').then(m => m.SellerportalModule)
    }]
  },
  {
    path: '',
    component: RealtorsLayoutComponent,
    children: [
    {
      path: 'realtor',
      loadChildren: () => import('./Realtors/Realtors.module').then(m => m.RealtorsModule)
    }]
  },
  {
    path: '',
    component: RealtorsportalLayoutComponent,
    children: [
    {
      path: 'realtorportal',
     loadChildren: () => import('./Realtors/Realtorsportal.module').then(m => m.RealtorsportalModule)
    }]
  }
  ,
  {
    path: '',
    component: LearnmoreLayoutComponent,
    children: [
    {
      path: 'learnmore',
     loadChildren: () => import('./Learnmore/Learnmore.module').then(m => m.LearnmoreModule)
    }]
  }
];
